// Templates.js
import React from 'react';
import QRcode from '../../img/QRCODEbardcode.png';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const DashboardTemplates = () => {


    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/createcustomtemplate');
    };

    return (
        <>
<div className="main-dashbord-contanir">
            <div className="MyTempletes">
                <div className="MyTempleteshehader">
                    <h1>Templete</h1>
                    <button onClick={handleClick}>
                        Add
                    </button>
                </div>

                <div className="MyTempletes--main">

                    <div className="templeteqrcode">
                        <img src={QRcode} alt="qrcode" />
                        <h3>You haven’t created any templates yet</h3>
                    </div>
                </div>


            </div>    
            </div>
        </>
    );
};

export default DashboardTemplates;
