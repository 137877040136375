import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import { baseurl } from '../baseurl';
import login from '../img/login-site.png';

const Signup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      return; // Add error handling here if needed
    }

    try {
      const response = await axios.post(`${baseurl}/signup`, formData);
      console.log(response);
      setFormData({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
      navigate('/NewQR');
    } catch (err) {
      console.error('Error:', err);
    }
  };

  return (
    <>
      <section className='login'>
        <div className="main-login">
          <div className="part-login-1">
            <form onSubmit={handleFormSubmit}>
              <h2>{t('signUp')}</h2>
              <p>{t('enterWithNetworks')}</p>

              <div className="login-input-group">
                <label>{t('name')}*</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleFormDataChange}
                />
              </div>

              <div className="login-input-group">
                <label>{t('email1')}*</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormDataChange}
                />
              </div>

              <div className="login-input-group">
                <label>{t('password')}*</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleFormDataChange}
                />
              </div>

              <div className="login-input-group">
                <label>{t('confirmPassword')}*</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleFormDataChange}
                />
              </div>

              <span>{t('forgotPassword')} <a href="#">{t('clickHere')}</a></span>

              <button type="submit">{t('signUp')}</button>

              <span>{t('haveAccount')} <a onClick={() => navigate("/login")}>{t('login')}</a></span>
            </form>
          </div>
          <div className="part-login-2">
            <img src={login} alt="" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;
