

// export const baseurl='http://localhost:5000/api'
// export const baseurl='https://1da4-2407-aa80-15-af8b-7442-780f-2161-731a.ngrok-free.app/api'


// export const baseurl='https://trackpaxbackend-f5c921ead279.herokuapp.com'

export const baseurl='https://qrcodebackendnazar-production.up.railway.app/api'






