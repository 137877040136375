import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { baseurl } from '../baseurl';
import login from '../img/login-site.png';

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    try {
      const response = await axios.post(`${baseurl}/login`, {
        email,
        password,
      });

      console.log(response);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      toast.success(t('loginSuccess'));

      setFormData({
        email: '',
        password: '',
      });
      navigate('/NewQR');
    } catch (err) {
      toast.error(t('invalidCredentials'));
    }
  };

  const sigup = () => {
    navigate("/signup");
  };

  return (
    <>
      <section className='login'>
        <ToastContainer />
        <div className="main-login">
          <div className="part-login-1">
            <form onSubmit={handleFormSubmit}>
              <h2>{t('login')}</h2>
              <p>{t('enterWithNetworks')}</p>

              <div className="login-input-group">
                <label>{t('email1')}*</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormDataChange}
                />
              </div>

              <div className="login-input-group">
                <label>{t('password')}*</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleFormDataChange}
                />
              </div>

              <span>{t('forgotPassword')} <a href="#">{t('clickHere')}</a></span>

              <button type="submit">{t('logIn')}</button>

              <span>{t('dontHaveAccount')} <a onClick={sigup}>{t('createAccount')}</a></span>
            </form>
          </div>
          <div className="part-login-2">
            <img src={login} alt="" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
